import React from "react";
import "./MobileHeader.scss";
import { ReactComponent as TypLogo } from "../../assets/img/typ-logo-white.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left-black.svg";
import { BackBtn } from "../../shared/Buttons";
import { Link } from "react-router-dom";

const MobileHeader = (props) => {
  return (
    <div className="mobile-header">
      <div style={{ flex: 1 }}>
        {props.backBtn && (
          <BackBtn backgroundColor="#51f8a8">
            <Link to={`/`} className="flex-row-center">
              <ArrowLeft width={24} height={32} />
            </Link>
          </BackBtn>
        )}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TypLogo style={{ width: 60 }} />
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default MobileHeader;
