import React from "react";
import styled from "styled-components";

const StyledButton = styled.div`
  padding-top: ${(props) => props.paddingVertical || "12px"};
  padding-bottom: ${(props) => props.paddingVertical || "12px"};
  width: ${(props) => props.width || "100%"};
  max-width: 350px;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  align-self: center;
  color: ${(props) => props.color || "#fff"};
  text-transform: ${(props) => props.textTransform};
  background-color: ${(props) => props.backgroundColor || "#001a4c"};
  border: ${(props) => props.border || "1px solid #000"};
  margin-top: ${(props) => props.marginTop || "24px"};
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    filter: opacity(65%);
  }

  &.disabled {
    filter: opacity(25%);
    cursor: initial;
    pointer-events: none;
  }
`;

export const Button = (props) => {
  return (
    <StyledButton
      className={props.disabled ? "disabled" : ""}
      {...props}
      onClick={() => props.handleclick()}
    >
      {props.children}
    </StyledButton>
  );
};

export const ButtonLink = (props) => {
  return (
    <StyledButton className={props.disabled ? "disabled" : ""} {...props}>
      {props.children}
    </StyledButton>
  );
};

export const WhatsappBtn = (props) => {
  return (
    <StyledButton
      onClick={() => props.handleclick()}
      className={props.disabled ? "disabled" : ""}
      marginTop={"8px"}
      paddingVertical={"12px"}
      {...props}
    >
      {props.children}
    </StyledButton>
  );
};
export const BackBtn = (props) => {
  return (
    <StyledButton
      style={{
        borderRadius: 50,
        height: props.height || 40,
        marginRight: 8,
      }}
      paddingVertical={"14px"}
      marginTop={"0px"}
      width={"40px"}
      {...props}
    >
      {props.children}
    </StyledButton>
  );
};
