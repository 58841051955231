import React from "react";

export const translate = (id, lan) => {
  if (lan.includes("en")) {
    lan = "en";
  }
  if (lan.includes("it")) {
    lan = "it";
  }
  if (lan.includes("es")) {
    lan = "es";
  }
  const text = texts.find((text) => text.id === id);
  const translation = text && text[lan];
  return translation || `${id}[daTradurre]`;
};

const texts = [
  {
    id: "trackingOrdine",
    it: "Tracking ordine",
    en: "Tracking order",
    es: "Seguimiento de pedido",
  },
  {
    id: "trackingNumero",
    it: "Tracking numero",
    en: "Tracking number",
    es: "Número de seguimiento",
  },
  {
    id: "indirizzo",
    it: "Indirizzo",
    en: "Address",
    es: "Dirección",
  },
  {
    id: "TYP",
    it: "TYP",
    en: "TYP",
    es: "TYP",
  },
  {
    id: "spedizioneNonTrovata",
    it: "Spedizione non trovata",
    en: "Shipment not found",
    es: "Envío no encontrado",
  },
  {
    id: "prenotazione",
    it: "Prenotazione",
    en: "Booking",
    es: "Reserva",
  },
  {
    id: "modalitaConsegna",
    it: "Modalità di consegna",
    en: "Delivery Methods",
    es: "Método de entrega",
  },
  {
    id: "pianifica",
    it: "Pianifica",
    en: "Plan",
    es: "Programe",
  },
  {
    id: "pianificaConsegna",
    it: "Pianifica la tua consegna",
    en: "Plan your delivery",
    es: "Programe su entrega",
  },
  {
    id: "prenotazionePersonalizzata",
    it: "Vuoi personalizzare la tua prenotazione?",
    en: "Do you want to customize your booking?",
    es: "¿Quieres elegir el día y franja horaria de su entrega?",
  },
  {
    id: "prenotazionePrevista",
    it: "Prenotazione prevista per il",
    en: "Booking planned on",
    es: "Entrega prevista para el",
  },
  {
    id: "consegnaEffettuata",
    it: "Consegna effettuata il",
    en: "Shipment delivered on",
    es: "Entrega efectuada el",
  },
  {
    id: "gestisciReso",
    it: "Gestisci il tuo reso",
    en: "Manage your return",
    es: "Gestione su devolución",
  },
  {
    id: "inserisciOrdine",
    it: "Inserisci il tuo numero di ordine per rintracciare il tuo pacco",
    en: "Enter your order number to track your package",
    es: "Introduzca su número de pedido para hacer seguimiento de su paquete",
  },
  {
    id: "gestioneReso",
    it: "Gestione reso",
    en: "Manage return",
    es: "Gestione su devolución",
  },
  {
    id: "indirizzoRitiro",
    it: "Indirizzo ritiro",
    en: "Pickup address",
    es: "Dirección de recogida",
  },
  {
    id: "dataRitiro",
    it: "Data ritiro",
    en: "Pickup date",
    es: "Fecha de recogida",
  },
  {
    id: "richiestaPianifica",
    it: "Richiesta ritiro pianificato",
    en: "Scheduled delivery request",
    es: "Solicitud de entrega programada",
  },
  {
    id: "numeroWhatsapp",
    it: "NUMERO WHATSAPP",
    en: "WHATSAPP NUMBER",
    es: "NÚMERO WHATSAPP",
  },
  {
    id: "attivaServizio",
    it: "ATTIVA SERVIZIO",
    en: "ACTIVATE SERVICE",
    es: "ACTIVE EL SERVICIO",
  },
  {
    id: "infoReso",
    it: "Informazioni sui cambi e resi",
    en: "Change and return informations",
    es: "Información sobre cambios y devoluciones",
  },
  {
    id: "inserisciNumero",
    it: "Ricevi gratuitamente sul tuo numero WhatsApp notifiche e aggiornamenti sullo stato delle tue spedizioni.",
    en: "Receive free notifications and updates on the status of your shipments on your WhatsApp number.",
    es: "Reciba notificaciones gratuitas sobre el estado de sus envíos en su número de WhatsApp.",
  },
  {
    id: "inserisciNumeroInterrompi",
    it: "Inserisci il tuo numero di Whatsapp per interrompere gli aggiornamenti sullo stato della tua spedizione",
    en: "Enter your Whatsapp number to stop updates on the status of your shipping",
    es: "Introduzca su número de Whatsapp para detener las actualizaciones sobre el estado de tu envío",
  },
  {
    id: "scegliOrario",
    it: "Scegli l'orario",
    en: "Choose the time slot",
    es: "Eliga una franja horaria",
  },
  {
    id: "restituzioneArticoli",
    it: "Restituzione Articoli",
    en: "Return goods",
    es: "Devolución del pedido",
  },
  {
    id: "motivazioneRichiesta",
    it: "Motivazione della richiesta",
    en: "Reason for your request",
    es: "Motivo de la solicitud",
  },
  {
    id: "pianificaReso",
    it: "Pianifica reso",
    en: "Plan Return",
    es: "Organice su devolución",
  },
  {
    id: "inviaFeedback",
    it: "Invia feedback e pianifica reso",
    en: "Send your feedback and plan the return",
    es: "Envíe su feedback y planifique una devolución",
  },
  {
    id: "destinazione",
    it: "Destinazione:",
    en: "Destination:",
    es: "Destino:",
  },
  {
    id: "consegnaSuccesso",
    it: "Consegna avvenuta con successo",
    en: "Delivery was successful",
    es: "Entrega realizada con éxito",
  },
  {
    id: "reso",
    it: "Reso",
    en: "Return",
    es: "Devolución",
  },
  {
    id: "motivo-del-reso",
    it: "Motivo del reso",
    en: "Return reason",
    es: "Motivo de la devolución",
  },
  {
    id: "delivery-booking",
    it: "Pianifica consegna",
    en: "Delivery booking",
    es: "Programación de su entrega",
  },
  {
    id: "richiesta-link",
    it: "Richiesta link",
    en: "Link request",
    es: "Solicitar link",
  },
  {
    id: "daStabilire",
    it: "Da stabilire",
    en: "To be established",
    es: "Por determinar",
  },
  {
    id: "NoSlotDisponibili",
    it: "Nessuno slot disponibile",
    en: "No slot available",
    es: "No hay ninguna franja horaria disponible",
  },
  {
    id: "ConsegnaNonInCarico",
    it: "Il corriere non ha ancora preso in carico la consegna",
    en: "The courier has not yet taken over the delivery",
    es: "El mensajero aún no ha realizado su entrega",
  },
  {
    id: "richiestaReso",
    it: "Richiesta reso",
    en: "Return request",
    es: "Solicitud de devolución",
  },
  {
    id: "richiedi",
    it: "Richiedi",
    en: "Request",
    es: "Solicite",
  },
  {
    id: "mailNonCorrispondente",
    it: "L'email/numero di telefono non corrisponde",
    en: "Email/phone number does not match",
    es: "El correo electrónico/número de teléfono móvil no coincide",
  },
  {
    id: "mailNonCorretta",
    it: "L'email e/o la passaword non è corretta, si prega di riprovare",
    en: "Wrong Email and/or password, please retry",
    es: "El correo electrónico y/o la contraseña es incorrecto, por favor inténtelo de nuevo",
  },
  {
    id: "linkInviato",
    it: "Il link per concludere il processo è stato inviato alla mail/numero indicato",
    en: "The link to complete the process has been sent to the email/number",
    es: "El link para finalizar el proceso ha sido enviado al correo/número indicado",
  },
  {
    id: "inserisciMail",
    it: "Inserisci la tua mail o il tuo numero di telefono per ricevere il link e finalizzare la richiesta",
    en: "Enter your email or phone number to receive the link and complete the request",
    es: "Introduzca su correo electrónico o número de móvil para recibir el li",
  },
  {
    id: "acquistoErrore",
    it: "acquisto per errore",
    en: "purchase by mistake",
    es: "Compra por error",
  },
  {
    id: "migliorPrezzo",
    it: "miglior prezzo disponibile",
    en: "best price available",
    es: "Mejor precio disponible",
  },
  {
    id: "qualitaNonAdeguate",
    it: "qualità non adeguate",
    en: "inadequate quality",
    es: "Calidades inadecuadas",
  },
  {
    id: "incomparabile",
    it: "incomparabile e inadeguato",
    en: "incomparable and inadequate",
    es: "Incomparable e inadecuado",
  },
  {
    id: "articoloDanneggiato",
    it: "articolo danneggiato, confezione esterna intatta",
    en: "damaged goods, intact package",
    es: "Artículo dañado, embalaje exterior intacto",
  },
  {
    id: "accessoriMancanti",
    it: "accessori mancanti",
    en: "missing parts-accessories",
    es: "Faltan accesorios",
  },
  {
    id: "articoloConfezioneDanneggiati",
    it: "articolo e confezione esterna danneggiati",
    en: "damaged goods and package",
    es: "Artículo y embalaje exterior dañados",
  },
  {
    id: "articoloErrato",
    it: "ricevuto articolo errato",
    en: "wrong goods",
    es: "Recibido artículo incorrecto",
  },
  {
    id: "return",
    it: "Pianifica reso",
    en: "Plan return",
    es: "Planifica devolucion",
  },
  {
    id: "accedi",
    it: "Accedi",
    en: "Login",
    es: "Acceder",
  },
  {
    id: "login",
    it: "Accedi",
    en: "Login",
    es: "Acceder",
  },
  {
    id: "salva",
    it: "Salva",
    en: "Save",
    es: "Guardar",
  },
  {
    id: "modificaIndirizzo",
    it: "Cambia indirizzo",
    en: "Change Address",
    es: "Cambiar dirección",
  },
  {
    id: "confermaIndirizzo",
    it: "Conferma indirizzo per",
    en: "Confirm address to",
    es: "Confirmar dirección para",
  },
  {
    id: "vedereDate",
    it: "vedere le date disponibili",
    en: "see available dates",
    es: "Ver las fechas disponibles",
  },
  {
    id: "inserisciTracking",
    it: "Inserisci il numero <br> di tracking per pianificare il reso",
    en: "Insert tracking number to plan the return",
    es: "Por favor, introduzca el número de seguimiento para programar su devolución",
  },
  {
    id: "pianificaSuccesso",
    it: "Consegna pianificata con successo",
    en: "Delivery planned successfully",
    es: "Entrega programada con éxito",
  },
  {
    id: "contattaci",
    it: "CONTATTACI",
    en: "CONTACT US",
    es: "CONTÁCTENOS",
  },
  {
    id: "assistenzaClienti",
    it: "ASSISTENZA CLIENTI",
    en: "CUSTOMER SERVICE",
    es: "SERVICIO DE ATENCIÓN AL CLIENTE",
  },
  {
    id: "invia",
    it: "INVIA MESSAGGIO",
    en: "SEND MESSAGE",
    es: "ENVIAR MENSAJE",
  },
  {
    id: "scriviRichiesta",
    it: "Invia il tuo messaggio",
    en: "Send your message",
    es: "Envíe su mensaje",
  },
  {
    id: "assistenza",
    it: "Contattaci per ricevere assistenza o informazione sulla tua spedizione",
    en: "Contact us to receive assistance or informations about your shipment",
    es: "Póngase en contacto con nosotros para obtener asistencia o información sobre su envío",
  },
  {
    id: "capNonCorrisponde",
    it: "CAP e città non corrispondenti",
    en: "Mismatch postcode and city",
    es: "El código postal y la ciudad no coinciden",
  },
  {
    id: "dataConsegnaNonDisponibile",
    it: "La data di consegna non è ancora disponibile",
    en: "The delivery date is not yet available",
    es: "La fecha de entrega no está disponible todavía",
  },
  {
    id: "ResoSuccesso",
    it: "Reso pianificato con successo",
    en: "Return planned successfully",
    es: "Devolución programada con éxito",
  },
  {
    id: "PianificaConsegnaSuccesso",
    it: "Consegna pianificata con successo",
    en: "Delivery planned successfully",
    es: "Entrega programada con éxito",
  },
  {
    id: "homepage",
    it: "Torna all'homepage",
    en: "Back to homepage",
    es: "Volver a la página de inicio",
  },
  {
    id: "colli",
    it: "Colli",
    en: "Parcels",
    es: "Bultos",
  },
  {
    id: "booking",
    it: "Booking",
    en: "Booking",
    es: "Reserva",
  },
  {
    id: "pod",
    it: "Pod",
    en: "Pod",
    es: "Justificante de entrega",
  },
  {
    id: "reservedAcceptance",
    it: "Accettato con riserva",
    en: "Reserved acceptance",
    es: "Aceptado con reservas",
  },
  {
    id: "reservedAcceptanceMessage",
    it: "Messaggio di accettazione",
    en: "Reserved acceptance message",
    es: "Mensaje de aceptación",
  },
  {
    id: "consigneeName",
    it: "Nome destinatario",
    en: "Consignee Name",
    es: "Nombre del destinatario",
  },
  {
    id: "signature",
    it: "Firma",
    en: "Signature",
    es: "Firma",
  },
  {
    id: "dateTime",
    it: "Data e ora",
    en: "Date and time",
    es: "Fecha y hora",
  },
  {
    id: "Si",
    it: "Si",
    en: "Yes",
    es: "Si",
  },
  {
    id: "posizioneNonDisponibile",
    it: "Posizione non disponibile",
    en: "Position not available",
    es: "Posición no disponible",
  },
  {
    id: "Invia",
    it: "INVIA",
    en: "SEND",
    es: "ENVIAR",
  },
  {
    id: "whatsapp",
    it: "Whatsapp",
    en: "Whatsapp",
    es: "Whatsapp",
  },
  {
    id: "whatsappSub",
    it: "Inserisci il numero di telefono sul quale desideri ricevere aggiornamenti sulle tue spedizioni. Importante: assicurati che il numero sia lo stesso fornito in fase di creazione dell'ordine e che abbia l'applicazione WhatsApp installata.",
    en: "Enter the phone number on which you wish to receive updates on your shipments. Important: make sure that the number is the same as provided when creating the order and that you have the WhatsApp application installed.",
    es: "Introduzca el número de teléfono en el que desea recibir actualizaciones sobre sus envíos. Importante: Asegúrese de que el número disponga de Whatsapp y que  sea el mismo número que el indicado durante la creación de su pedido.",
  },
  {
    id: "cancelWhatsappSub",
    it: "Inserisci il tuo numero di telefono e ti invieremo un codice OTP temporaneo da inserire per interrompere gli aggiornamenti",
    en: "Enter your phone number and we will send you a temporary OTP code to enter to stop updates",
    es: "Introduzca su número de teléfono y le enviaremos un código  temporal para confirmar la cancelación de notificaciones.",
  },
  {
    id: 404,
    it: "Il numero non corrisponde con quello inserito al momento dell'ordine. Riprova o contatta il servizio clienti",
    en: "The number does not match the one entered at the time of the order. Please try again or contact customer service",
    es: "El número no coincide con el número introducido en el momento del pedido. Inténtelo de nuevo o póngase en contacto con el Servicio de Atención al Cliente.",
  },
  {
    id: 401,
    it: "Non hai l'autorizzazione per eseguire questa operazione",
    en: "You do not have permission to perform this operation",
    es: "No tiene autorización para hacer esta operación",
  },
  {
    id: 409,
    it: "Servizio già abilitato su questo numero",
    en: "Mobile number already enabled",
    es: "Servicio ya habilitado en este número",
  },
  {
    id: 409,
    it: "Servizio già abilitato su questo numero",
    en: "Mobile number already enabled",
    es: "Servicio ya habilitado en este número",
  },
  {
    id: 403,
    it: "Numero OTP sbagliato",
    en: "Wrong otp number",
    es: "Código incorrecto",
  },
  {
    id: "verifica",
    it: "VERIFICA",
    en: "CHECK",
    es: "COMPRUEBE",
  },
  {
    id: "verificaOtp",
    it: "Verifica OTP",
    en: "Check OTP",
    es: "Verifique código",
  },
  {
    id: "verificaOtpSub",
    it: "Inserisci il codice OTP inviato al numero",
    en: "Enter the OTP code sent to the number",
    es: "Introduzca el código enviado al número",
  },
  {
    id: "numeroVerificato",
    it: "Il tuo numero è verificato",
    en: "Your number has been verified",
    es: "Su número ha sido verificado",
  },
  {
    id: "numeroVerificato",
    it: "Il tuo numero è verificato",
    en: "Your number has been verified",
    es: "Su número ha sido verificado",
  },
  {
    id: "ricezioneAggiornamenti",
    it: "Riceverai aggiornamenti sul tuo stato di spedizione tramite Whatsapp",
    en: "You will receive updates on your shipping status via Whatsapp",
    es: "Recibirá actualizaciones sobre el estado de su envío a través de Whatsapp",
  },
  {
    id: "interrompiAggiornamento",
    it: "INTERROMPI AGGIORNAMENTI",
    en: "STOP UPDATES",
    es: "DETENER NOTIFICACIONES",
  },
  {
    id: "aggiornamentiInterrotti",
    it: "Gli aggiornamenti sul tuo stato di spedizione tramite Whatsapp sono stati interrotti",
    en: "Updates on your shipping status via Whatsapp have been stopped",
    es: "Las actualizaciones de su estado de envío a través de Whatsapp han sido interrumpidas",
  },
  {
    id: "ritirata",
    it: "In ritiro",
    en: "Picked up",
    es: "Recogido",
  },
  {
    id: "registrata",
    it: "Registrata",
    en: "Registered",
    es: "Registrado",
  },
  {
    id: "inTransito",
    it: "In viaggio",
    en: "On the way",
    es: "En camino",
  },
  {
    id: "inConsegna",
    it: "In consegna",
    en: "In delivery",
    es: "En entrega",
  },
  {
    id: "consegnata",
    it: "Consegnata",
    en: "Delivered",
    es: "Entregado",
  },
  {
    id: "data",
    it: "Data",
    en: "Date",
    es: "Fecha",
  },
  {
    id: "orario",
    it: "Orario",
    en: "Time",
    es: "Horario",
  },
  {
    id: "status",
    it: "Status",
    en: "Status",
    es: "Estado",
  },
  {
    id: "ordineInConsegna",
    it: "Il tuo ordine è in consegna",
    en: "Your order is on delivery",
    es: "Su pedido está en entrega",
  },
  {
    id: "ordineConsegnato",
    it: "Il tuo ordine è stato consegnato",
    en: "Your order has been delivered",
    es: "Su pedido ha sido entregado",
  },
  {
    id: "inPreparazione",
    it: "In preparazione",
    en: "Under preparation",
    es: "En preparación",
  },
  {
    id: "disposizioniNonRicevute",
    it: "In attesa di disposizioni dal mittente",
    en: "Provisions not yet received",
    es: "En espera de las disposiciones del remitente",
  },
  {
    id: "disposizioniRicevute",
    it: "Disposizioni ricevute",
    en: "Provisions received",
    es: "Disposiciones recibidas",
  },
  {
    id: "oggettoRichiesta",
    it: "Oggetto richiesta",
    en: "Request subject",
    es: "Objeto requerido",
  },
  {
    id: "testoRichiesta",
    it: "Testo richiesta",
    en: "Write here your request",
    es: "Escriba aquí su solicitud",
  },
  {
    id: "inserisciTuoNumero",
    it: "Inserisci il tuo numero (con prefisso nazionale)",
    en: "Insert your phone number (with country code)",
    es: "Introduzca su número (con prefijo nacional)",
  },
  {
    id: "selezionaSlot",
    it: "Seleziona uno slot",
    en: "Select a slot",
    es: "Seleccione una franja horaria",
  },
  {
    id: "companyName",
    it: "Società",
    en: "Company Name",
    es: "Nombre de empresa",
  },
  {
    id: "buildingNr",
    it: "Numero civico",
    en: "Building Number",
    es: "Número del portal",
  },
  {
    id: "referencePerson",
    it: "Destinatario",
    en: "Reference Name",
    es: "Destinatario",
  },
  {
    id: "postalCode",
    it: "CAP",
    en: "Postal code",
    es: "Código postal",
  },
  {
    id: "capNonDisponibile",
    it: "CAP non disponibile",
    en: "Postal Code non available",
    es: "Código postal no disponible",
  },
  {
    id: "city",
    it: "Città",
    en: "City",
    es: "Ciudad",
  },
  {
    id: "country",
    it: "Nazione",
    en: "Country",
    es: "País",
  },
  {
    id: "note",
    it: "Nota",
    en: "Note",
    es: "Nota",
  },
  {
    id: "address",
    it: "Indirizzo",
    en: "Address",
    es: "Dirección",
  },
  {
    id: "phoneNumber",
    it: "Numero di telefono",
    en: "Phone number",
    es: "Número de teléfono",
  },
  {
    id: "indicazioniSvincolo",
    it: "Status giacenza - istruzioni svincolo",
    en: "Storage status - Release instructions",
    es: "Guardado en depósito, a la espera de instrucciones",
  },
  {
    id: "attesaIstruzioni",
    it: "In attesa di istruzioni",
    en: "Provisions not yet received",
    es: "A la espera de instrucciones",
  },
  {
    id: "da",
    it: "da",
    en: "from",
    es: "Desde",
  },
  {
    id: "ordine",
    it: "Ordine",
    en: "Order",
    es: "Pedido",
  },
  {
    id: "nazioneNonDisponibile",
    it: "Nazione non disponibile",
    en: "Country not available",
    es: "Nación no disponible",
  },
];

const defaultLanguage = "en";

export const Text = (props) => {
  let lan = localStorage.getItem("lan") || defaultLanguage;
  // if (!lan.includes("it") || !lan.includes("en")) {
  //   lan = "en";
  // }
  // return <div {...props}>{translate(props.label, lan) + (props.additional || '')}</div>;
  return (
    <div
      style={{ color: "#001A4C" }}
      {...props}
      dangerouslySetInnerHTML={{
        __html: translate(props.label, lan) + (props.additional || ""),
      }}
    />
  );
};
