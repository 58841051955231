import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { Text } from "../../assets/lemmata";
import { Card } from "../../components/Cards/Cards";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection from "../../components/SideSection/SideSection";
import { BackBtn, ButtonLink } from "../../shared/Buttons";
import "./ReturnGoodsPage.scss";
import {
  getTrackingData,
  getTrackingDataWithCode,
  getTrackingDataWithCustomerCode,
} from "../../reducers/shippings";

const ReturnGoodsPage = (props) => {
  const lan = localStorage.getItem("lan") || navigator.language.toLowerCase();
  const id = localStorage.getItem("id");
  const key = localStorage.getItem("key");
  const reference = localStorage.getItem("reference");
  const customerCode = localStorage.getItem("customerCode");
  const customerReference = localStorage.getItem("customerReference");
  const deliveredEvent = props.shipping.events?.find(
    (event) => event.eventCode === 1
  );
  console.log(deliveredEvent, "deliveredEvent");
  useEffect(async () => {
    window.scrollTo(0, 0);
    if (props.shipping === null) {
      if (key) {
        await props.getTrackingData(id, key);
      }
      if (reference) {
        localStorage.removeItem("id");
        localStorage.removeItem("key");
        await props.getTrackingDataWithCode(reference);
      }
      if (customerReference) {
        localStorage.removeItem("id");
        localStorage.removeItem("key");
        await props.getTrackingDataWithCustomerCode(
          customerReference,
          customerCode
        );
      }
    }
  }, [props.shipping]);
  return (
    <div className="main-container">
      <SideSection />
      <div className="right-container">
        <MobileHeader backBtn />
        <div className="flex-row title-container hide-title">
          <div className="desktop-btn">
            <BackBtn>
              <Link to={`/`} className="flex-row-center">
                <ArrowLeft width={24} height={32} />
              </Link>
            </BackBtn>
          </div>
          <div className="title-style">
            <Text label="gestioneReso" />
          </div>
        </div>
        <div style={{ padding: "0 5% 24px", minHeight: "80vh" }}>
          <Card backgroundColor="#f3f5f4">
            <div
              className="flex-row"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <div className="responsive-section">
                <div style={{ fontSize: 20, paddingBottom: 8 }}>
                  <Text label="consegnaSuccesso" />
                </div>
                <div
                  style={{ fontSize: 24, fontWeight: 600 }}
                  className="light-weight"
                >
                  {deliveredEvent ? (
                    moment(deliveredEvent.dateTime)
                      .locale(lan)
                      .format("DD MMMM YYYY")
                  ) : props.shipping?.booking?.bookingDate ? (
                    moment(props.shipping?.booking?.bookingDate)
                      .locale(lan)
                      .format("DD MMMM YYYY")
                  ) : (
                    <Text label="daStabilire" />
                  )}
                </div>
              </div>
              <div className="responsive-section right-section">
                <div>
                  <div style={{ paddingBottom: 12 }}>
                    <Text style={{ fontWeight: 500 }} label="destinazione" />
                  </div>
                  {props.shipping?.deliveryAddress && (
                    <div>
                      <p>{props.shipping?.deliveryAddress?.referencePerson}</p>
                      <p>
                        {props.shipping?.deliveryAddress?.street}{" "}
                        {props.shipping?.deliveryAddress?.buildingNr}
                      </p>
                      <p>
                        {props.shipping?.deliveryAddress?.postalCode},{" "}
                        {props.shipping?.deliveryAddress?.city}
                      </p>
                      {/* <p>{props.shipping.deliveryAddress.country}</p> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-row-center">
              <Link to="/return" className="link-style">
                <ButtonLink>
                  <Text
                    style={{ color: "#fff" }}
                    label="restituzioneArticoli"
                  />
                </ButtonLink>
              </Link>
            </div>
          </Card>
        </div>
        <MobileFooter />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  shipping: state.shippings.data,
  loading: state.shippings.loading,
  error: state.shippings.error,
});
export default connect(mapStateToProps, {
  getTrackingData,
  getTrackingDataWithCode,
  getTrackingDataWithCustomerCode,
})(ReturnGoodsPage);
