import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { translate } from "../assets/lemmata";

function format() {
  const lan = localStorage.getItem("lan") || "it-it";
  let address = window.location.pathname.split("/");
  const translatedAddress = translate(address[1], lan);
  if (address[1] !== "") {
    return translatedAddress;
  } else {
    return "";
  }
}

function MyHelmet() {
  const [path, setPath] = useState(format());
  const { pathname } = useLocation();

  useEffect(() => {
    setPath(format());
  }, [pathname]);
  return (
    <Helmet>
      {/* <link rel="icon" href={favicon32} sizes="32x32" />
      <link rel="icon" href={favicon180} sizes="192x192" />
      <link rel="apple-touch-icon" href={favicon192} />
      <meta name="msapplication-TileImage" content={favicon270} /> */}
      <title>
        Typ {path && "-"} {path}
      </title>
    </Helmet>
  );
}

export default MyHelmet;
