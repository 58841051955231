import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import DayPlannerPage from "./pages/DayPlannerPage/DayPlannerPage";
import ReturnGoodsPage from "./pages/ReturnGoodsPage/ReturnGoodsPage";
import ReturnReasons from "./pages/ReturnReasons/ReturnReasons";
import LinkRequest from "./pages/LinkRequest/LinkRequest";
import TrackingPage from "./pages/TrackingPage/TrackingPage";
import Login from "./pages/Login/Login";
import rootReducer from "./reducers/index.js";
import "./styles/General.scss";
import MyHelmet from "./utils/helmets";

export const store = createStore(rootReducer, applyMiddleware(thunk));

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MyHelmet />
        <Switch>
          <Route exact path="/" component={TrackingPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/reso" component={ReturnGoodsPage} />
          <Route exact path="/booking" component={DayPlannerPage} />
          <Route exact path="/return" component={ReturnReasons} />
          <Route exact path="/richiesta-link" component={LinkRequest} />
          {/* <Route path="/pagina-non-trovata" component={Error} /> */}
          <Redirect to="/pagina-non-trovata" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
