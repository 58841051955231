import axios from "axios";
import api from "../server/api";

const GET_SLOTS_REQUEST = "TYP/GET_SLOTS_REQUEST";
const GET_SLOTS_SUCCESS = "TYP/GET_SLOTS_SUCCESS";
const GET_SLOTS_FAILURE = "TYP/GET_SLOTS_FAILURE";

// const GET_SLOTS_BY_ADDRESS_REQUEST = "TYP/GET_SLOTS_BY_ADDRESS_REQUEST";
// const GET_SLOTS_BY_ADDRESS_SUCCESS = "TYP/GET_SLOTS_BY_ADDRESS_SUCCESS";
// const GET_SLOTS_BY_ADDRESS_FAILURE = "TYP/GET_SLOTS_BY_ADDRESS_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

export default function reducerSlots(state = initialState, action) {
  switch (action.type) {
    case GET_SLOTS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_SLOTS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };

    default:
      return state;
  }
}

export const getSlots = (date, key) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SLOTS_REQUEST,
    });
    try {
      const { url, method, headers, params, data } = api.getSlots(date, key);

      const slots = await axios({
        url,
        method,
        headers,
        params,
        data,
      });
      console.log("partito", slots.data.dateAndSlots);
      dispatch({
        type: GET_SLOTS_SUCCESS,
        payload: slots.data.dateAndSlots,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
      dispatch({
        type: GET_SLOTS_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSlotsByAddress = (data, key) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SLOTS_REQUEST,
    });
    try {
      const { url, method, headers, params } = await api.getSlotsByAddress(
        data,
        key
      );
      console.log("PARAMS", params);
      console.log("DATA", data);
      const slots = await axios({
        url,
        method,
        headers,
        data,
        params,
      });

      dispatch({
        type: GET_SLOTS_SUCCESS,
        payload: slots.data.dateAndSlots,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error ", error.response);
      dispatch({
        type: GET_SLOTS_FAILURE,
        payload: error.response,
      });
    }
  };
};
