import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Text } from "../../assets/lemmata";
import { Card } from "../../components/Cards/Cards";
// import MobileFooter from "../../components/MobileFooter/MobileFooter";
// import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { Button } from "../../shared/Buttons";
import { Input } from "../../shared/Inputs";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import { ReactComponent as EyeSlash } from "../../assets/icons/eye-slash.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as Typ } from "../../assets/img/typ-logo.svg";
// import { login } from "../../reducers/account";

const lan = localStorage.getItem("lan") || "it";

const inputList = [
  {
    placeholder: lan.includes("it") ? "Indirizzo mail" : "Email address",
    target: "username",
    type: "text",
    icon: <Mail width={24} height={24} />,
  },
  {
    placeholder: lan.includes("it") ? "Password" : "Password",
    target: "password",
    type: "password",
    visibleType: "text",
    icon: <EyeSlash width={24} height={24} />,
    visibleIcon: <Eye width={24} height={24} />,
    handleIconClick: true,
  },
];

const Login = (props) => {
  const [state, setState] = useState({});
  const [visibility, setVisibility] = useState(false);

  const handleChange = (target, value) => {
    setState((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const _isDisabled = () => {
    const inputStatus = inputList.map(
      (input) => !![input.target] && state[input.target] !== ""
    );
    const inputAreValid = inputStatus.some((input) => !!input);
    return !inputAreValid;
  };

  const handleclick = async () => {
    // await props.login(state);
  };

  const handleIconClick = () => {
    setVisibility(!visibility);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("apiKey")) {
      window.location.href = "/";
    }
  }, [props.account]);

  return (
    <div
      //   className="main-container"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        {/* <MobileHeader /> */}

        <div
          style={{
            padding: "0 5% 24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Typ width={200} />
          <Card half paddingCard="4px 10%" fontSize="1rem" titleAlign="center">
            {inputList.map((input, i) => (
              <Input
                width="100%"
                key={`key_${input.target}`}
                target={input.target}
                placeholder={input.placeholder}
                handleChange={handleChange}
                type={visibility ? input.visibleType : input.type}
                icon={
                  !visibility ? input.icon : input.visibleIcon || input.icon
                }
                handleIconClick={input.handleIconClick && handleIconClick}
              />
            ))}
          </Card>

          {props.error && (
            <Text
              label="mailNonCorretta"
              style={{ fontSize: 12, fontWeight: 500, color: "crimson" }}
            />
          )}
          <div className="flex-row-center">
            <Button
              handleclick={() => handleclick()}
              disabled={_isDisabled()}
              backgroundColor="#51ffaa"
              color="#000"
              border="1px solid #51ffaa"
            >
              <Text label="accedi" />
            </Button>
          </div>
        </div>
        {/* <MobileFooter /> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account.data,
  loading: state.account.loading,
  error: state.account.error,
});

export default connect(mapStateToProps, {})(Login);
