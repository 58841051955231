import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/it";
import "moment/locale/es";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as Timer } from "../../assets/icons/timer.svg";
import { Text, translate } from "../../assets/lemmata";
import { getSlots, getSlotsByAddress } from "../../reducers/slots";
import {
  CalendarArrowLeft,
  CalendarArrowLeftDisabled,
  CalendarArrowRight,
} from "../../shared/Arrows";
import { Button } from "../../shared/Buttons";
import { Select } from "../../shared/Inputs";
import "./Calendar.scss";
import { daylist, formatCalendar } from "./CalendarFunc";
import { Spinner } from "../../shared/Spinner";
import { createBooking } from "../../reducers/bookings";

const Calendar = (props) => {
  const [selectedTime, setSelectedTime] = useState(moment());
  const [selectedSlot, setSelectedSlot] = useState("");
  const [weekDays, setWeekDays] = useState(moment.weekdays());
  const lan = localStorage.getItem("lan");
  const key = localStorage.getItem("key");

  // weekDays.push(weekDays.splice(0, 1)[0]);
  // console.log(weekDays)

  const getDaySlots = (day, slots) => {
    let daySlots = { slots: [] };
    if (slots.length > 0) {
      const match = slots.find(
        (slot) => slot.date === moment(day).format("YYYY-MM-DD")
      );
      if (match) {
        daySlots = match;
      }
    }
    console.log("DAYSLOTS", daySlots);
    return daySlots;
  };
  const selectedMonth = moment(selectedTime).month();
  const calendar = formatCalendar(moment(selectedTime));

  useEffect(() => {
    moment.locale(lan);
    if (lan.includes("it") || lan.includes("es")) {
      let w = weekDays.splice(0, 1);
      console.log("w", w);
      setWeekDays([...weekDays, w[0]]);
    }
  }, []);
  useEffect(() => {
    if (props.setCapError) {
      if (props.errorSlots.data?.message === "Cannot parse the address") {
        props.setCapError(true);
      } else {
        props.setCapError(false);
      }
    }
  }, [props.errorSlots]);

  useEffect(() => {
    if (props.address) {
      props.getSlotsByAddress(
        {
          dates: daylist(),
          address: props.address,
          serviceCode: props.serviceCode,
        },
        { key: key, shipmentId: props.trackingId }
      );
    }
  }, [props.address]);

  useEffect(() => {
    if (
      moment(selectedTime).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
    ) {
      setSelectedTime(moment());
    }
  }, [selectedTime]);

  useEffect(() => {
    moment.locale(lan);
    const firstDayOfMonth = moment(selectedTime)
      .startOf("month")
      .format("YYYY-MM-DD");
    setSelectedTime(firstDayOfMonth);
    if (props.address) {
      props.getSlotsByAddress(
        {
          dates: daylist(selectedTime),
          address: props.address,
          serviceCode: props.serviceCode,
        },
        { key: key, shipmentId: props.trackingId }
      );
    } else {
      props.getSlots(
        {
          dates: daylist(selectedTime),
          shipmentId: props.trackingId,
        },
        key
      );
    }
  }, [selectedMonth]);

  const _addMonth = () => {
    setSelectedTime(moment(selectedTime).add(1, "months"));
  };

  const _subtractMonth = () => {
    setSelectedTime(moment(selectedTime).subtract(1, "months"));
  };

  const isValidDate = (date) => {
    return (
      moment(selectedTime).month() === moment(date).month() &&
      moment(date).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
    );
  };

  const setDay = (day) => {
    setSelectedTime(moment(day).format("YYYY-MM-DD"));
  };

  const setSlot = (e) => {
    if (e.includes("-")) {
      const arr = e.split("-");
      setSelectedSlot({
        startTimeRange: arr[0].trim(),
        endTimeRange: arr[1].trim(),
      });
    } else {
      setSelectedSlot({
        startTimeRange: null,
        endTimeRange: null,
      });
    }
  };

  const _handleClick = async () => {
    const response = await props.createBooking(
      {
        date: selectedTime,
        shipmentId: props.trackingId,
        ...selectedSlot,
      },
      key
    );

    if (response) {
      props.setPopupOpen(true);
    }
  };
  const selectedSlotsList = getDaySlots(selectedTime, props.slots).slots;

  return (
    <div className="calendario " style={{ paddingTop: props.noPadding && 0 }}>
      {props.loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="month-section">
            {moment(selectedTime).format("MMMM") !== moment().format("MMMM") ? (
              <CalendarArrowLeft
                style={{ flex: 1 }}
                handleclick={_subtractMonth}
              />
            ) : (
              <CalendarArrowLeftDisabled style={{ flex: 1 }} disabled />
            )}
            <div className="month">{moment(selectedTime).format("MMMM")}</div>
            <CalendarArrowRight style={{ flex: 1 }} handleclick={_addMonth} />
          </div>
          <div className="heading-calendario-aste">
            {weekDays.map((element, i) => (
              <div key={`key_${element}_${i}`} className="day">
                {element.slice(0, 2)}
              </div>
            ))}
          </div>
          <div className="blocks">
            {calendar.map((week, i) => {
              return week.map((day) => {
                return (
                  <DayBlock
                    key={`day_${day}`}
                    day={day}
                    isValidDate={isValidDate}
                    selectedTime={selectedTime}
                    setDay={setDay}
                    handleDayChange={props.handleDayChange}
                  />
                );
              });
            })}
          </div>
          {selectedSlotsList.length > 0 &&
          selectedSlotsList.some((slot) => slot.available === true) ? (
            <div className="calendar-input-container">
              <Text label="scegliOrario" />
              <div
                className="flex-row"
                style={{
                  backgroundColor: "#f5f5f5",
                  marginTop: 16,
                  borderRadius: 50,
                }}
              >
                <Timer
                  width={20}
                  height={20}
                  style={{
                    flex: 1,
                    paddingLeft: 8,
                  }}
                />
                <Select
                  onChange={
                    props.handleSlotChange
                      ? (e) => props.handleSlotChange(e.target.value)
                      : (e) => setSlot(e.target.value)
                  }
                  style={{ borderRadius: 50 }}
                  className="calendar-select"
                >
                  <option fromtime={""} totime={""}>
                    {translate("selezionaSlot", lan)}
                  </option>
                  {selectedSlotsList.map(
                    (slot, i) =>
                      slot.available && (
                        <option
                          key={`slot_${i}_${slot.fromTime}_${slot.toTime}`}
                          fromtime={slot.fromTime}
                          totime={slot.toTime}
                        >
                          {slot.fromTime}:00 - {slot.toTime}:00
                        </option>
                      )
                  )}
                </Select>
              </div>
              {!props.noBtn && (
                <div className="flex-row-center">
                  <Button handleclick={() => _handleClick()}>
                    <Text style={{ color: "#fff" }} label="pianifica" />
                  </Button>
                </div>
              )}
              {/* {props.booking === "success" && (
                <Text
                  style={{
                    marginTop: 8,
                    color: "#51f8a8",
                    textAlign: "center",
                  }}
                  label="pianificaSuccesso"
                />
              )} */}
            </div>
          ) : (
            <div style={{ paddingTop: 16 }}>
              <Text style={{ color: "#001a4c" }} label="NoSlotDisponibili" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language.language,
  booking: state.bookings.data,
  slots: state.slots.data,
  loading: state.slots.loading,
  errorSlots: state.slots.error,
  error: state.bookings.error,
});

export default connect(mapStateToProps, {
  getSlots,
  getSlotsByAddress,
  createBooking,
})(Calendar);

const DayBlock = (props) => {
  const isSelectedDay = (date) => {
    return (
      moment(props.selectedTime).format("DD MMMM YYYY") ===
      moment(date).format("DD MMMM YYYY")
    );
  };

  return (
    <div>
      <div
        key={`day_${props.day}`}
        onClick={() => {
          props.setDay(props.day);
          if (props.handleDayChange) {
            props.handleDayChange(moment(props.day).format("YYYY-MM-DD"));
          }
        }}
        className={`block ${props.isValidDate(props.day) ? "" : "disabled"}
          ${isSelectedDay(props.day) ? "active" : ""}
        }`}
      >
        {moment(props.day).format("DD")}
      </div>
    </div>
  );
};
