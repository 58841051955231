import React from "react";
import styled from "styled-components";
import { Text } from "../assets/lemmata";
import { ButtonLink } from "./Buttons";

const prefixesList = [
  { value: "+39", label: "+39" },
  { value: "+33", label: "+33" },
  ,
  { value: "+49", label: "+49" },
  ,
  { value: "+31", label: "+31" },
  { value: "+34", label: "+34" },
  { value: "+351", label: "+351" },
];
const StyledSelect = styled.select`
  padding: ${(props) => props.padding || "16px 0"};
  font-size: 16px;
  width: ${(props) => props.width || "100%"};
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) => props.border || "none"};
  border-radius: 10px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export const Select = (props) => {
  return <StyledSelect {...props}>{props.children}</StyledSelect>;
};

const StyledInput = styled.input`
  padding: ${(props) =>
    props.prefixSelect
      ? "16px 16px 16px 100px"
      : props.icon
      ? "16px 16px 16px 56px"
      : "16px 24px"};
  font-size: 16px;
  width: ${(props) => props.width || "100%"};
  margin: 12px 0;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) =>
    props.border || props.error ? "2px red solid" : "2px #f5f5f3 solid"};
  border-radius: 50px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Poppins", sans-serif;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

const StyledTextarea = styled.textarea`
  padding: ${(props) => (props.icon ? "16px 16px 16px 56px" : "16px 24px")};
  min-height: 25vh;
  flex-grow: 1;
  resize: none;
  font-size: 16px;
  width: ${(props) => props.width || "100%"};
  margin: 12px 0;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) => props.border || "none"};
  border-radius: 24px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Poppins", sans-serif;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  height: 24px;
`;

export const Input = (props) => {
  if (props.textarea) {
    return (
      <div style={{ position: "relative" }}>
        <StyledTextarea
          disabled={props.disabled}
          onChange={(e) => props.handleChange(props.target, e.target.value)}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          type={props.type}
          {...props}
        />
      </div>
    );
  } else {
    return (
      <>
        <div style={{ position: "relative" }}>
          <StyledInput
            disabled={props.disabled}
            maxLength={props.maxLength}
            onChange={(e) => props.handleChange(props.target, e.target.value)}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            type={props.type}
            value={props.value}
            {...props}
          />
          {props.handleIconClick ? (
            <IconContainer
              style={{ cursor: "pointer" }}
              onClick={() => props.handleIconClick()}
            >
              {props.icon}
            </IconContainer>
          ) : (
            <IconContainer>{props.icon}</IconContainer>
          )}
          {props.prefixSelect && (
            <StyledSelect
              width="40px"
              padding="17px 0"
              style={{
                position: "absolute",
                top: 2,
                left: 56,
                fontFamily: "Poppins",
                textAlign: "center",
                borderRadius: 0,
                boxShadow: "0 0 3px #aaa",
              }}
              onChange={(e) => props.prefixSelectHandleChange(e.target.value)}
            >
              {" "}
              {prefixesList.map((prefix, i) => (
                <option
                  style={{ fontFamily: "Poppins" }}
                  key={`prefix${i}_${prefix.value}`}
                  value={prefix.value}
                >
                  {prefix.label}
                </option>
              ))}
            </StyledSelect>
          )}
        </div>
        {props.error && (
          <Text
            style={{
              fontSize: "0.8rem",
              fontWeight: "500",
              color: "red",
              // paddingLeft: "56px",
            }}
            label={props.errorLabel}
          />
        )}
      </>
    );
  }
};

const StyledInputWithButton = styled.input`
  padding: 0 5% 0 15%;
  width: 200%;
  height: 48px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: 2px solid #000;
  border-right: ${(props) => props.borderRight || "#2px solid transparent"};
  background-color: ${(props) => props.backgroundColor || "#51f8a8"};
  color: #000;
  outline: none;
  font-family: "Poppins", sans-serif;
  &::placeholder {
    color: #000;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const InputWithButton = (props) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        maxWidth: "350px",
      }}
    >
      <StyledInputWithButton
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        onChange={(e) => props.handleChange(e.target.value)}
        onKeyDown={(e) => props.handleKeyDown(e)}
        {...props}
      />
      {props.icon}
      <ButtonLink
        // disabled={props.btnDisabled}
        onClick={() => props.handleclick()}
        marginTop={"0px"}
        paddingVertical={0}
        color="#fff"
        backgroundColor="#000"
        style={{
          marginLeft: -2,
          height: 48,
          borderBottomRightRadius: 50,
          borderTopRightRadius: 50,
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
        }}
      >
        <Text
          style={{ fontSize: 12, fontWeight: "bold" }}
          label={props.label}
        />
      </ButtonLink>
    </div>
  );
};

const StyledSingleDigitInput = styled.input`
  padding: ${(props) => props.padding || "16px 24px"};
  font-size: 16px;
  width: 80px;
  margin: 12px 0;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) =>
    props.border || props.error ? "2px red solid" : "2px #f5f5f3 solid"};
  border-radius: 50px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Poppins", sans-serif;
  @media (max-width: 564px) {
    width: 48px;
    height: 48px;
    padding: 0;
  }
  text-align: center;
`;

export const SingleDigitInput = (props) => {
  return (
    <>
      <StyledSingleDigitInput
        maxLength={props.maxLength}
        onChange={(e) => {
          props.handleFocus(e);
          props.handleChange(props.target, e.target.value);
        }}
        defaultValue={props.defaultValue}
        type={props.type}
        value={props.value}
        onKeyDown={props.onKeyPress}
      />
    </>
  );
};
