import axios from "axios";
import api from "../server/api";

const ELEVATE_ACCOUNT_SUCCESS = "TYP/ELEVATE_ACCOUNT_SUCCESS";
const ELEVATE_ACCOUNT_REQUEST = "TYP/ELEVATE_ACCOUNT_REQUEST";
const ELEVATE_ACCOUNT_FAILURE = "TYP/ELEVATE_ACCOUNT_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

export default function reducerSubscriptions(state = initialState, action) {
  switch (action.type) {
    case ELEVATE_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: false };
    case ELEVATE_ACCOUNT_SUCCESS:
      // console.log("payload", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ELEVATE_ACCOUNT_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    // case LOGOUT_ACCOUNT:
    //   return { ...initialState };

    default:
      return state;
  }
}

export const elevatePrivileges = (credentials) => {
  return async (dispatch) => {
    dispatch({
      type: ELEVATE_ACCOUNT_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.elevatePrivileges(credentials);
      const account = await axios({
        url,
        method,
        headers,
        data,
      });
      if (account) {
        dispatch({
          type: ELEVATE_ACCOUNT_SUCCESS,
          payload: "success",
        });
      } else {
        dispatch({
          type: ELEVATE_ACCOUNT_FAILURE,
          payload: "no_account_found",
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "account_not_approved"
      ) {
        dispatch({
          type: ELEVATE_ACCOUNT_FAILURE,
          payload: "account_not_approved",
        });
      } else {
        dispatch({
          type: ELEVATE_ACCOUNT_FAILURE,
          payload: "error_login",
        });
      }
    }
  };
};

export const logout = () => {
  // return async (dispatch) => {
  //   dispatch({
  //     type: LOGOUT_ACCOUNT,
  //   })
  localStorage.clear();
};
