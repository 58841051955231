import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { Text, translate } from "../../assets/lemmata";
import { Card } from "../../components/Cards/Cards";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection from "../../components/SideSection/SideSection";
import { BackBtn, Button } from "../../shared/Buttons";
import { Input } from "../../shared/Inputs";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone-solid.svg";
import { elevatePrivileges } from "../../reducers/account";

const lan = localStorage.getItem("lan") || "it-it";
const viewportHeight = window.innerHeight;

const inputList = [
  {
    placeholder: translate("email", lan),
    target: "email",
    type: "email",
    icon: <Mail width={24} height={24} />,
  },
  {
    placeholder: translate("phoneNumber", lan),
    target: "mobile",
    type: "tel",
    icon: <Phone width={24} height={24} />,
  },
];

const LinkRequest = (props) => {
  const location = useLocation();
  const id = localStorage.getItem("id");
  const action = location.state.action;

  const [state, setState] = useState({
    shipment: {
      id: props.shipping?.shipmentId || id,
      reference: props.shipping?.reference || null,
    },
    action: action,
  });

  const handleChange = (target, value) => {
    setState((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const _isDisabled = () => {
    const inputStatus = inputList.map(
      (input) => !![input.target] && state[input.target] !== ""
    );
    const inputAreValid = inputStatus.some((input) => !!input);
    return !inputAreValid;
  };

  const handleclick = () => {
    props.elevatePrivileges(state);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-container">
      <SideSection />
      <div
        className="right-container-noscroll"
        style={{ backgroundColor: "#fff", height: viewportHeight }}
      >
        <MobileHeader backBtn />
        <div style={{ flexGrow: 1 }}>
          <div className="flex-row title-container">
            <div className="desktop-btn">
              <BackBtn>
                <Link to="/" className="flex-row-center">
                  <ArrowLeft width={24} height={32} />
                </Link>
              </BackBtn>
            </div>
            <div className="title-style">
              <Text label={location.state.title} />
            </div>
          </div>
          <div
            style={{
              padding: "0 5% 24px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card
              half
              paddingCard="4px 10%"
              paddingCardMobile="4px 0px"
              cardTitle={<Text label="inserisciMail" />}
              fontSize="1rem"
              titleAlign="center"
            >
              {inputList.map((input, i) => (
                <Input
                  key={`key_${input.target}`}
                  target={input.target}
                  placeholder={input.placeholder}
                  handleChange={handleChange}
                  type={input.type}
                  icon={input.icon}
                />
              ))}
            </Card>

            {props.error && (
              <Text
                label="mailNonCorrispondente"
                style={{ fontSize: 12, fontWeight: 500, color: "crimson" }}
              />
            )}
            {props.account === "success" && (
              <Text
                label="linkInviato"
                style={{ fontSize: 12, fontWeight: 500, color: "forestgreen" }}
              />
            )}
            <div className="flex-row-center">
              <Button
                marginTop="4px"
                backgroundColor="#51ffaa"
                color="#000"
                border="1px solid #51ffaa"
                handleclick={() => handleclick()}
                disabled={_isDisabled()}
              >
                <Text style={{ fontWeight: 500 }} label="richiedi" />
              </Button>
            </div>
          </div>
        </div>
        <MobileFooter />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shipping: state.shippings.data,
  account: state.account.data,
  loading: state.account.loading,
  error: state.account.error,
});

export default connect(mapStateToProps, { elevatePrivileges })(LinkRequest);
