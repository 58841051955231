import React from "react";
import "./MobileFooter.scss";
import { ReactComponent as ArceseLogo } from "../../assets/icons/ArceseLogo.svg";

const MobileFooter = () => {
  return (
    <div className="mobile-footer">
      <div className="flex-row-center">
        <span style={{ fontSize: 10, paddingRight: 4, fontWeight: "light" }}>
          Powered by
        </span>
        <ArceseLogo width={80} />
      </div>
    </div>
  );
};

export default MobileFooter;
