import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Package } from "../../assets/icons/packageBlu.svg";
import { ReactComponent as Timer } from "../../assets/icons/timer-white.svg";
// import { ReactComponent as TimerBlack } from "../../assets/icons/timer.svg";
// import { ReactComponent as WhatsappLogo } from "../../assets/icons/whatsapp.svg";
import { Text, translate } from "../../assets/lemmata";
import { Card } from "../../components/Cards/Cards";
import LanguageSelection from "../../components/LanguageSelection/LanguageSelection";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection, {
  PopUpWhatsapp,
  PopUpContact,
  HelpSection,
} from "../../components/SideSection/SideSection";
import {
  getShippingLogo,
  getTrackingData,
  getTrackingDataWithCode,
  getTrackingDataWithCustomerCode,
} from "../../reducers/shippings";
import { setReduxLanguage } from "../../reducers/language";
import { ButtonLink } from "../../shared/Buttons";
// import { CheckCircle, LightCheckCircle } from "../../shared/CheckCircle";
import { Spinner } from "../../shared/Spinner";
import "./TrackingPage.scss";
// import { InputWithButton } from "../../shared/Inputs";
import { tConvert } from "../../utils/formatHours";
import PodMap from "../../components/Map/Map";
import { activateNotifications, sendMessage } from "../../reducers/messages";
// import { api } from "../../config/server";
import { ReactComponent as Phone } from "../../assets/icons/smartphone.svg";
import { useSelector } from "react-redux";
import TrackingSection from "../../components/TrackingSection/TrackinSection";
import { groupEventsByState } from "../../components/TrackingSection/FormatEventsFunc";
import { useDispatch } from "react-redux";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
// const isWhatsappAvailable = api.includes("dev") ? true : false;

const lan = localStorage.getItem("lan") || "it";
const TrackingPage = (props) => {
  const dispatch = useDispatch();

  const optInEnabled = useSelector(
    ({ shippings }) => shippings.data?.optInEnabled || null
  );
  const shippingLogo = useSelector(
    ({ shippings }) => shippings.shippingLogo || null
  );
  // const lan = localStorage.getItem("lan") || navigator.language.toLowerCase();

  const [language, setLanguage] = useState(
    localStorage.getItem("lan") || navigator.language.toLowerCase()
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [whatsappInfo, setWhatsappInfo] = useState(null);
  // const [trackingCode, setTrackingCode] = useState("");
  const [shipping, setShipping] = useState(false);

  const id = useQuery().get("id") || localStorage.getItem("id");
  const key = useQuery().get("key") || localStorage.getItem("key");
  const urlId = useQuery().get("id");
  const urlKey = useQuery().get("key");
  const reference = useQuery().get("reference") || null;
  const customerCode = useQuery().get("customerCode");
  const customerReference = useQuery().get("customerReference");
  const [verificationComplete, setVerificationComplete] = useState(false);

  const isWhatsappAvailable = props.shipping?.contractOptions?.whatsappStream;

  const isShippingDelivered = () => {
    if (props?.shipping?.events) {
      const isDelivered =
        props.shipping.events[props.shipping.events.length - 1]?.eventCode ===
        1;
      return isDelivered;
    } else {
      return false;
    }
  };

  const isShippingDelivering = () => {
    if (props?.shipping?.events) {
      const isDelivering =
        props.shipping.events[props.shipping.events.length - 1]?.eventCode ===
        52;
      return isDelivering;
    } else {
      return false;
    }
  };

  const isReturnPickedUp = () => {
    if (props?.shipping?.events) {
      const isPickedUp = props.shipping?.events.some(
        (event) => event.eventCode === 37
      );
      const isReturn = props.shipping.serviceCode === "R";
      return isPickedUp && isReturn;
    } else {
      return false;
    }
  };

  const isReturnAvailable = () => {
    return (
      props.shipping?.serviceCode?.toLowerCase() !== "s2c" &&
      props.shipping?.serviceCode?.toLowerCase() !== "r" &&
      isShippingDelivered() &&
      props.shipping.contractOptions?.endUserReturnAvailable &&
      props.shipping.provisionModeId !== 1
    );
  };

  const isAssistanceAvailable = () => {
    return (
      props.shipping?.serviceCode?.toLowerCase() === "sc" ||
      props.shipping?.serviceCode?.toLowerCase() === "r" ||
      props.shipping?.serviceCode?.toLowerCase() === "bc"
    );
  };

  // console.log("quindi", props.shipping?.serviceCode?.toLowerCase() === "r");
  const isSchedulingAvailable = () => {
    return (
      !isShippingDelivered() &&
      // !isShippingDelivering() &&
      !isReturnPickedUp() &&
      // props.shipping.contractOptions.endUserReturnAvailable &&
      (props.shipping?.serviceCode?.toLowerCase() === "sc" ||
        props.shipping?.serviceCode?.toLowerCase() === "r" ||
        props.shipping?.serviceCode?.toLowerCase() === "bc")
      // ||
      // props.shipping?.serviceCode?.toLowerCase() === "r"
    );
  };

  const inputList = [
    {
      placeholder: translate("oggettoRichiesta", lan),
      target: "subject",
    },
    {
      placeholder: translate("testoRichiesta", lan),

      target: "body",
      textarea: true,
    },
  ];

  const whatsappInputList = [
    // {
    //   placeholder: lan.includes("it")
    //     ? "Inserisci il tuo nome"
    //     : "Insert your name",
    //   target: "name",
    // },
    {
      placeholder: translate("inserisciTuoNumero", lan),
      target: "to",
      icon: <Phone width={24} height={24} />,
      errorLabel: "numeroNonValido",
      prefixSelect: true,
    },
  ];

  const _handleClick = async () => {
    const response = await props.sendMessage(message, id);
    if (response) {
      setIsPopupOpen(false);
    }
  };

  const _handleWhatsappClick = async () => {
    let number = whatsappInfo.to;
    if (whatsappInfo.to.startsWith("+")) {
      number = whatsappInfo.to.substring(1);
    }
    if (whatsappInfo.to.startsWith("00")) {
      number = whatsappInfo.to.substring(2);
    }

    const response = await activateNotifications(whatsappInfo.name, number);

    if (response) {
      setIsPopupOpen(false);
    }
  };

  const handleChange = (target, value) => {
    setMessage({
      ...message,
      [target]: value,
    });
  };

  const handleWhatsappChange = (target, value) => {
    setWhatsappInfo({
      ...whatsappInfo,
      [target]: value,
    });
  };

  const formatTimeData = (timeData) => {
    const timeDataSplitted = timeData.split("-");
    let dataStart = timeDataSplitted[0].trim();
    let dataEnd = timeDataSplitted[1].trim();
    if (dataStart.length < 3) {
      dataStart = dataStart + ":00";
    }
    if (dataEnd.length < 3) {
      dataEnd = dataEnd + ":00";
    }
    if (language.includes("it")) {
      return isShippingDelivered()
        ? "tra le " + dataStart + " e le " + dataEnd
        : "dalle " + dataStart + " alle " + dataEnd;
    } else {
      return isShippingDelivered()
        ? "between " + tConvert(dataStart) + " and " + tConvert(dataEnd)
        : "from " + tConvert(dataStart) + " to " + tConvert(dataEnd);
    }
  };

  // useEffect(() => {
  //   localStorage.removeItem("id");
  //   localStorage.removeItem("key");

  // }, []);

  useEffect(() => {
    if (reference) {
      localStorage.removeItem("id");
      localStorage.removeItem("key");

      dispatch(getTrackingDataWithCode(reference, language));
    }
  }, [reference, language]);

  useEffect(() => {
    if (customerReference) {
      localStorage.removeItem("id");
      localStorage.removeItem("key");
      dispatch(
        getTrackingDataWithCustomerCode(
          customerReference,
          customerCode,
          language
        )
      );
    }
  }, [customerReference, language]);

  useEffect(() => {
    if (urlId || urlKey) {
      // localStorage.setItem("lan", language);
      window.scrollTo(0, 0);
      localStorage.removeItem("customerCode");
      localStorage.removeItem("customerReference");
      localStorage.removeItem("reference");
      localStorage.removeItem("id");
      localStorage.removeItem("key");

      localStorage.setItem("id", id);
      if (urlKey) {
        localStorage.setItem("key", urlKey);
      }
      dispatch(getTrackingData(id, key));
    }
  }, [id, key]);

  useEffect(() => {
    // localStorage.setItem("lan", language);
    if (id || key) {
      dispatch(getTrackingData(id, key, language));
    }
  }, [language]);

  useEffect(() => {
    if (
      language.includes("it") ||
      language.includes("en") ||
      language.includes("es")
    ) {
      localStorage.setItem("lan", language);
      moment.locale(language);
      props.setReduxLanguage(language);
    } else {
      localStorage.setItem("lan", "en");
      moment.locale("en");
      props.setReduxLanguage("en");
    }
  }, [language]);

  useEffect(() => {
    if (props.shipping?.contractId) {
      dispatch(getShippingLogo(props.shipping.contractId));
    }
    if (props.shipping?.shipmentId) {
      setShipping(true);
    } else {
      setShipping(false);
    }
  }, [props.shipping]);

  const selectLanguage = (lan) => {
    setLanguage(lan);
    setReduxLanguage(lan);
  };

  console.log("SHIPPING", props.shipping);

  return (
    <>
      {isPopupOpen === "contact" && (
        <PopUpContact
          title="scriviRichiesta"
          close={() => setIsPopupOpen(false)}
          inputs={inputList}
          disabled={!message.subject || !message.body}
          handleClick={_handleClick}
          handleChange={handleChange}
          buttonLabel="invia"
        />
      )}
      {isPopupOpen === "whatsapp" && (
        <PopUpWhatsapp
          title="whatsapp"
          subtitle="whatsappSub"
          secondPageTitle="verificaOtp"
          secondPageSubtitle="verificaOtpSub"
          close={() => setIsPopupOpen(false)}
          inputs={whatsappInputList}
          disabled={!whatsappInfo?.to}
          handleclick={_handleWhatsappClick}
          handleChange={handleWhatsappChange}
          buttonLabel="attivaServizio"
          secondPageButtonLabel="verifica"
          shipmentId={id}
          verificationComplete={verificationComplete}
          setVerificationComplete={setVerificationComplete}
        />
      )}
      <div className="main-container">
        <SideSection
          id={props.match.params.id}
          key={props.match.params.key}
          isReturnAvailable={isReturnAvailable}
          isAssistanceAvailable={isAssistanceAvailable}
          isWhatsappAvailable={isWhatsappAvailable}
          serviceCode={props.shipping?.serviceCode}
        />
        <div className="right-container">
          <MobileHeader />
          <div className="main-header">
            <div className="container-logo">
              {shippingLogo && (
                <img
                  className="logo"
                  src={`data:${shippingLogo.contentType};base64, ${shippingLogo.content}`}
                  alt={shippingLogo.fileName}
                />
              )}
            </div>

            <LanguageSelection selectLanguage={selectLanguage} />
          </div>
          {/*////////////////////////// TRACKING SEARCHBAR ///////////////////////*/}
          {/* {!urlKey && (
          <div className="tracking-section">
            <Text
              style={{
                fontSize: "1.5rem",
                fontWeight: 600,
                paddingBottom: "16px",
              }}
              label="trackingNumero"
            />
            <InputWithButton
              defaultValue={reference}
              handleChange={handleChange}
              placeholder={
                lan.includes('it') ? "NUMERO TRACKING" : "TRACKING NUMBER"
              }
              icon={<Package className="input-icon" />}
              label={"TYP"}
              backgroundColor="#fff"
              handleclick={handleclick}
              handleKeyDown={handleKeyDown}
              btnDisabled={!trackingCode}
            />
            {props.error?.response?.status === 404 && (
              <Text
                style={{
                  color: "crimson",
                  paddingTop: 12,
                  fontSize: "12px",
                  fontWeight: 500,
                }}
                label="spedizioneNonTrovata"
              />
            )}
          </div>
        )} */}
          {!shipping ? (
            <div
              style={{
                height: "90vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 24,
              }}
            >
              {/* <Text
                style={{ fontSize: 24, color: "#bbb", fontWeight: 600 }}
                label="inserisciOrdine"
              /> */}
            </div>
          ) : (
            <div style={{ padding: "32px 5% " }}>
              <h2
                style={{
                  fontWeight: 600,
                  color: "#001A4C",
                }}
              >
                {`${translate("trackingOrdine", language)} ${
                  props.shipping.shipmentId
                }`}
              </h2>
              <p
                style={{
                  marginBottom: "2rem",
                  color: "#001A4C",
                }}
              >
                {(props.shipping.orderNumber || props.shipping.reference) &&
                  `${translate("ordine", language)} ${
                    props.shipping.orderNumber || props.shipping.reference
                  }`}
                {props.shipping?.serviceCode !== "R" &&
                  props.shipping.senderAddress?.companyName &&
                  ` ${translate("da", language)} ${
                    props.shipping.senderAddress.companyName
                  }`}
              </p>
              <TrackingSection
                trackingEvents={groupEventsByState(
                  props.shipping.events,
                  props.shipping.provisionModeId
                )}
                storageId={props.shipping.warehouseStorageId}
                provisionId={props.shipping.provisionModeId}
                provisionLabel={props.shipping.provisionModeDescription}
              />
              {props.shipping.provisionModeDescription && (
                <Card
                  className="card"
                  cardTitle={<Text label="indicazioniSvincolo" />}
                  cardJustifyContent="space-between"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ color: "#001a4c", fontSize: "0.9rem" }}>
                      {props.shipping.provisionModeDescription}
                    </div>
                    <div style={{ color: "#001a4c", fontSize: "0.9rem" }}>
                      {moment(
                        props.shipping.events.filter(
                          (event) => event.eventCode === 35
                        )[0].dateTime
                      ).format("DD/MM/YYYY - HH:mm")}
                    </div>
                  </div>
                </Card>
              )}
              {props.shipping.events[props.shipping.events.length - 1]
                .eventCode === 35 &&
                !props.shipping.provisionModeDescription && (
                  <Card
                    className="card"
                    cardTitle={<Text label="indicazioniSvincolo" />}
                    cardJustifyContent="space-between"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{ color: "#001a4c", fontSize: "0.9rem" }}
                        label="attesaIstruzioni"
                      />
                      <div style={{ color: "#001a4c", fontSize: "0.9rem" }}>
                        {moment(
                          props.shipping.events.filter(
                            (event) => event.eventCode === 35
                          )[0].dateTime
                        ).format("DD/MM/YYYY - HH:mm")}
                      </div>
                    </div>
                  </Card>
                )}
              {/*////////////////////////// OLD TRACKING ///////////////////////*/}
              {/* <Card
                paddingTopContainer="4px"
                cardTitle={
                  <Text
                    label="trackingOrdine"
                    additional={
                      props.shipping?.orderNumber
                        ? " nr. " + props.shipping?.orderNumber
                        : null
                    }
                  />
                }
              >
                {props.loading ? (
                  <Spinner />
                ) : (
                  props.shipping?.events &&
                  props.shipping.events.map((event, i) => {
                    console.log(event);
                    // const datetime = `${event.date}T${event.time}Z`;
                    const datetime = event.dateTime;
                    return (
                      <div
                        key={`key_${event.date}_${i}`}
                        className="tracking-step"
                      >
                        {moment(datetime).format("YYYY-MM-DD") <
                        moment().format("YYYY-MM-DD") ? (
                          <LightCheckCircle />
                        ) : moment(datetime).format("YYYY-MM-DD") ===
                            moment().format("YYYY-MM-DD") &&
                          moment(datetime).format("HH:mm:ss") <
                            moment().format("HH:mm:ss") ? (
                          <LightCheckCircle />
                        ) : (
                          <CheckCircle />
                        )}
                        <div className="tracking-step-right">
                          <p className="tracking-step-title">
                            {event.eventName}
                          </p>
                          <p
                            className="tracking-step-data"
                            style={{ color: "#000" }}
                          >
                            {event.warehouseName}
                          </p>

                          {moment(datetime).format("YYYY-MM-DD") <
                          moment().format("YYYY-MM-DD") ? (
                            <div className="flex-row">
                              <TimerBlack
                                width={20}
                                height={20}
                                style={{ marginRight: 8 }}
                              />

                              <p className="tracking-step-data">
                                {moment(datetime).format("HH:mm")}
                                {", "}
                                {moment(datetime).format("DD MMMM YYYY")}
                              </p>
                            </div>
                          ) : moment(datetime).format("YYYY-MM-DD") ===
                              moment().format("YYYY-MM-DD") &&
                            moment(datetime).format("HH:mm:ss") <
                              moment().format("HH:mm:ss") ? (
                            <div className="flex-row">
                              <TimerBlack
                                width={20}
                                height={20}
                                style={{ marginRight: 8 }}
                              />

                              <p className="tracking-step-data">
                                {moment(datetime).format("HH:mm")}
                                {", "}
                                {moment(datetime).format("DD MMMM YYYY")}
                              </p>
                            </div>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </Card> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {isSchedulingAvailable() && (
                  <Card
                    className="card"
                    half
                    cardTitle={<Text label="prenotazione" />}
                    cardJustifyContent={
                      !isShippingDelivering() && "space-between"
                    }
                  >
                    {props.loading ? (
                      <Spinner />
                    ) : // ) : !props.shipping?.estimatedDeliveryDate ? (
                    props.shipping?.events <= 1 ? (
                      // <div>
                      <div
                        style={{
                          alignItems: "center",
                          minHeight: 192,
                        }}
                        className="flex-row-center"
                      >
                        <Text
                          style={{ fontSize: 18 }}
                          label="ConsegnaNonInCarico"
                        />
                      </div>
                    ) : (
                      <>
                        <div>
                          <Text label="prenotazionePrevista" />
                        </div>
                        <div className="deliveryDate">
                          {/* {props.shipping?.estimatedDeliveryDate ? (
                        moment(props.shipping.estimatedDeliveryDate).format( */}
                          {props.shipping?.booking?.bookingDate ? (
                            moment(props.shipping?.booking?.bookingDate).format(
                              "DD MMMM YYYY "
                            ) +
                            formatTimeData(
                              props.shipping?.booking?.slotTimeRange
                            )
                          ) : (
                            <Text
                              style={{ fontSize: 20 }}
                              label="dataConsegnaNonDisponibile"
                            />
                          )}
                        </div>
                        {!isShippingDelivering() &&
                          props.shipping?.serviceCode?.toLowerCase() !==
                            "r" && (
                            <>
                              <Text
                                className="light-weight"
                                label="prenotazionePersonalizzata"
                              />
                              <div className="flex-row-center">
                                <Link
                                  className="link-style"
                                  to={
                                    key
                                      ? {
                                          pathname: "/booking",
                                        }
                                      : {
                                          pathname: "/richiesta-link",
                                          state: {
                                            action: "BOOKING",
                                            title: "richiestaPianifica",
                                          },
                                        }
                                  }
                                >
                                  <ButtonLink
                                    color="#fff"
                                    backgroundColor="#001A4C"
                                  >
                                    <Timer
                                      width={24}
                                      height={24}
                                      style={{ marginRight: 8 }}
                                    />
                                    <Text
                                      style={{ color: "#fff" }}
                                      label="pianifica"
                                    />
                                  </ButtonLink>
                                </Link>
                              </div>
                            </>
                          )}
                      </>
                    )}
                  </Card>
                )}
                {props.shipping?.pod && (
                  <Card
                    minHeight={isSchedulingAvailable() && "240px"}
                    half
                    cardTitle={<Text label="pod" />}
                    width="66%"
                  >
                    {props.loading ? (
                      <Spinner />
                    ) : (
                      props.shipping?.pod && (
                        <div className="pod-container">
                          <div className="pod-map">
                            <PodMap
                              lat={props.shipping?.pod?.latitude}
                              lon={props.shipping?.pod?.longitude}
                            />
                            {props.shipping?.pod?.latitude === null && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  backgroundColor: "white",
                                  opacity: 0.8,
                                  height: 240,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Text
                                  style={{ fontSize: 20, fontWeight: 500 }}
                                  label="posizioneNonDisponibile"
                                />
                              </div>
                            )}
                          </div>
                          <div className="pod-data">
                            <div>
                              <Text
                                style={{ fontWeight: 600 }}
                                label={"dateTime"}
                              />
                              {moment(props.shipping?.pod?.dateTime).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </div>{" "}
                            <div>
                              <Text
                                style={{ marginTop: 12, fontWeight: 600 }}
                                label={"consigneeName"}
                              />
                              {props.shipping?.pod?.consigneeName}
                            </div>{" "}
                            {props.shipping?.pod?.reservedAcceptance && (
                              <>
                                <div>
                                  <Text
                                    style={{ marginTop: 12, fontWeight: 600 }}
                                    label={"reservedAcceptance"}
                                  />
                                  {props.shipping?.pod?.reservedAcceptance ? (
                                    <Text label="Si" />
                                  ) : (
                                    "No"
                                  )}
                                </div>
                                <div>
                                  <Text
                                    style={{ marginTop: 12, fontWeight: 600 }}
                                    label={"reservedAcceptanceMessage"}
                                  />
                                  {
                                    props.shipping?.pod
                                      ?.reservedAcceptanceMessage
                                  }
                                </div>
                              </>
                            )}
                            {/* <div style={{ paddingLeft: 16 }}></div> */}
                            <Text
                              label={"signature"}
                              style={{
                                fontWeight: 600,
                                paddingTop: 12,
                              }}
                            />
                            <img
                              style={{ maxWidth: "100%" }}
                              src={`data:image/${props.shipping?.pod?.signature?.contentType};base64, ${props.shipping?.pod?.signature?.content}`}
                              alt={props.shipping?.pod?.signature?.fileName}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </Card>
                )}
                <Card
                  minHeight={
                    isSchedulingAvailable() &&
                    !isShippingDelivering() &&
                    "240px"
                  }
                  half
                  width={props.shipping?.pod && "32%"}
                  cardTitle={<Text label="indirizzo" />}
                >
                  {props.loading ? (
                    <Spinner />
                  ) : (
                    props.shipping?.deliveryAddress && (
                      <div className="address-lines">
                        <div>
                          {props.shipping.deliveryAddress.referencePerson}
                        </div>
                        <div>
                          {props.shipping.deliveryAddress.street}{" "}
                          {props.shipping.deliveryAddress.buildingNr}
                        </div>
                        <div>
                          {props.shipping.deliveryAddress.postalCode},{" "}
                          {props.shipping.deliveryAddress.city}
                        </div>
                        <div>{props.shipping.deliveryAddress.country}</div>
                      </div>
                    )
                  )}
                </Card>
              </div>
            </div>
          )}
          <div className="mobile-buttons-container">
            {isAssistanceAvailable() && (
              <HelpSection
                title="assistenzaClienti"
                body="assistenza"
                handleClick={() => setIsPopupOpen("contact")}
                buttonLabel="contattaci"
                backgroundColor="#fff"
              />
            )}
            {isWhatsappAvailable && (
              <HelpSection
                title="whatsapp"
                body={
                  !optInEnabled
                    ? "inserisciNumero"
                    : "inserisciNumeroInterrompi"
                }
                handleClick={() => setIsPopupOpen("whatsapp")}
                buttonLabel={
                  !optInEnabled ? "attivaServizio" : "interrompiAggiornamento"
                }
              />
            )}

            {isReturnAvailable() && (
              <div
                style={{
                  padding: "32px 10%",
                  borderTopWidth: 1,
                  borderTopColor: "#e2e2e2",
                  borderTopStyle: "solid",
                  backgroundColor: "#fff",
                }}
              >
                <div className="flex-row" style={{ paddingBottom: 8 }}>
                  <Package width={28} height={28} style={{ marginRight: 8 }} />
                  <div>
                    <Text
                      style={{ fontSize: 14, color: "#001a4c" }}
                      label="infoReso"
                    />
                  </div>
                </div>
                <div className="flex-row-center">
                  <Link
                    className="link-style"
                    to={
                      key
                        ? {
                            pathname: "/reso",
                          }
                        : {
                            pathname: "/richiesta-link",
                            state: {
                              action: "RETURN",
                              title: "richiestaReso",
                            },
                          }
                    }
                  >
                    <ButtonLink
                      backgroundColor="#001a4c"
                      border="1px solid #001a4c"
                      marginTop={"8px"}
                      style={{
                        fontSize: 10,
                        textTransform: "uppercase",
                        fontWeight: 700,
                      }}
                    >
                      <Text style={{ color: "#fff" }} label="gestisciReso" />
                    </ButtonLink>
                  </Link>
                </div>
              </div>
            )}
          </div>
          <MobileFooter />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  shipping: state.shippings.data,
  loading: state.shippings.loading,
  error: state.shippings.error,
});

export default connect(mapStateToProps, {
  setReduxLanguage,
  sendMessage,
})(TrackingPage);
